body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vCard {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 3fr;
  width: 75vw;
  height: 40vh;
  top: 25vh;
  //border: 1px solid black;
  background-image: radial-gradient(farthest-corner at 70%, white, #bebcbc 70%);
  margin: auto;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 3px 0px rgba(0, 0, 0, 0.65);
  .info {
    position: relative;
    margin: 0;
    h1 {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0.3vh 0.6vw;
      margin: 0;
      font-size: 3vh;
    }
  }
  .image {
    background-image: url("assets/tom-wiesseckel-vCard.png");
    height: 40vh;
    background-size: cover;
    background-position: 0 85px;
    background-repeat: no-repeat;
    span {
      position: absolute;
      padding: 0.1vh 0.2vw;
      right: 0.4vw;
      bottom: 0.3vh;
      background-color: white;
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
      font-size: 1vh;
    }
  }
}
